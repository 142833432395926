.bio-chart {
    > .row {
        .col-3 {
            @media screen and (max-width: 576px) {
            }
        }
    }
}

.bio-chart-text-blue {
    color: #0a68f480;
}

.bio-chart-text-yellow {
    color: #fbb911;
}

.bio-chart-text-red {
    color: #e7323280;
}

.bio-chart-text-green {
    color: #209663;
}

.bio-chart-bg-blue {
    background-color: rgba(10, 104, 244, 0.1);
}

.bio-chart-bg-blue-active {
    background-color: rgba(10, 104, 244, 1);
    > div {
        > span {
            color: #ffffff;
            font-weight: bold;
        }
    }
}

.bio-chart-bg-yellow {
    background-color: rgba(251, 185, 17, 0.1);
}

.bio-chart-bg-yellow-active {
    background-color: rgba(251, 185, 17, 1);
    > div {
        > span {
            color: #ffffff;
            font-weight: bold;
        }
    }
}

.bio-chart-bg-red {
    background-color: rgba(231, 50, 50, 0.1);
}

.bio-chart-bg-red-active {
    background-color: rgba(231, 50, 50, 1);
    > div {
        > span {
            color: #ffffff;
            font-weight: bold;
        }
    }
}

.bio-chart-bg-green {
    background-color: rgba(32, 150, 99, 0.1);
}

.bio-chart-bg-green-active {
    background-color: rgba(32, 150, 99, 1);
    > div {
        > span {
            color: #ffffff;
            font-weight: bold;
        }
    }
}

.text-active-chart {
    color: #ffffff;
    font-weight: bold;
}

.bio-chart-box {
    width: 152px !important;
    height: 152px !important;
    @media screen and (max-width: 1600px) {
        width: 140px !important;
        height: 140px !important;
    }
    @media screen and (max-width: 1400px) {
        width: 115px !important;
        height: 115px !important;
    }
    @media screen and (max-width: 1230px) {
        width: 80px !important;
        height: 80px !important;
    }
    @media screen and (max-width: 992px) {
        width: 55px !important;
        height: 55px !important;
    }
    @media screen and (max-width: 768px) {
        width: 86px !important;
        height: 86px !important;
    }
    @media screen and (max-width: 576px) {
        width: 86px !important;
        height: 86px !important;
    }
}

.bio-chart-last-row {
    width: 152px !important;
    @media screen and (max-width: 1600px) {
        width: 140px !important;
    }
    @media screen and (max-width: 1400px) {
        width: 115px !important;
    }
    @media screen and (max-width: 1230px) {
        width: 80px !important;
    }
    @media screen and (max-width: 992px) {
        width: 55px !important;
    }
    @media screen and (max-width: 768px) {
        width: 86px !important;
    }
    @media screen and (max-width: 576px) {
        width: 86px !important;
    }
}

#button-tooltip .tooltip-inner {
    max-width: 300px !important;
}