@media screen and (max-width: 576px) {
    .report-tab-horizontal-scroll {
        overflow-x: scroll;
    }

    .report-tab-horizontal-scroll > .row {
        flex-wrap: nowrap;
        min-width: 680px;
    }

    .report-tab-horizontal-scroll > .row > .col-4 {
        max-width: 200px;
    }

    .report-tab-horizontal-scroll > .row > .col-2 {
        max-width: 120px;
    }

    .report-tab-horizontal-scroll {
        overflow-x: scroll;
    }
}
