.farm-address {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #62666c;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.farm-title {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    color: #474c55;
}

.farm-description {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #62666c;
}

.farm-property {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #474c55;
}

.farm-modal-title {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 36px;
    text-align: center;
    letter-spacing: 0.4px;
    color: #474c55;
}

.farm-modal-custom {
    width: 50vw;
    max-width: 828px !important;
}

.farm-modal-custom .modal-content {
    border-radius: 4px;
    border: none;
}

.farm-modal-custom .btn-close {
    background: #F7F8F9;
}

.farm-modal-custom .modal-header {
    border: none;
}

.farm-modal-custom .modal-footer {
    border: none;
}