@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;400&display=swap');
@import './shared/styles/custom-bootstrap.scss';
@import './shared/styles/shared.scss';

html {
  height: 100%;
  font-family: 'Times New Roman', Times, serif;
}

body {
  height: 100%;
  margin: 0;
  font-family: 'Roboto', sans-serif, 'Inter';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  height: 100%;
}
