.side-bar-label {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    //color: var(--pro-sidebar-label-color);
}

.pro-item-content {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
}

.pro-inner-list-item {
    background: var(--pro-menu-item-bg);
}

.pro-sidebar .pro-menu .pro-menu-item.active {
    background: var(--pro-menu-item-bg);
    border-radius: 4px;
}

.pro-sidebar .pro-menu .pro-menu-item.active .pro-icon {
    background: var(--pro-menu-item-bg);
}

.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item {
    padding-left: 15px !important;
}

.drawer-custom {
    background-color: #e6f3ec;
}

.drawer-language-setting-lable {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: #474c55;
}

a {
    text-decoration: none !important;
}

.nav-bar-xs {
    background-color: rgba(0, 131, 60, 0.1);
}

:root {
    --sidebar-bg-color: #f7f8fa;
    --sidebar-color: #f7f8fa;
    --sidebar-width: 230px;
    --sidebar-collapsed-width: 65px;
    --highlight-color: #d8d8d8;
    --submenu-bg-color: #2b2b2b;
    --submenu-bg-color-collapsed: #f7f8fa;
    --icon-bg-color: #f7f8fa;
    --icon-size: 35px;
    --submenu-indent: 24px;
    --breakpoint-xs: 480px;
    --breakpoint-sm: 576px;
    --breakpoint-md: 768px;
    --breakpoint-lg: 992px;
    --breakpoint-xl: 1200px;
    --breakpoint-xxl: 1600px;
    --pro-menu-item-bg: rgba(0, 131, 60, 0.08);
    --pro-sidebar-label-color: #62666c;
}

$sidebar-bg-color: var(--sidebar-bg-color);
$sidebar-color: var(--sidebar-color);
$sidebar-width: var(--sidebar-width);
$sidebar-collapsed-width: var(--sidebar-collapsed-width);
$highlight-color: var(--highlight-color) !default;
$submenu-bg-color: var(--submenu-bg-color) !default;
$submenu-bg-color-collapsed: var(--submenu-bg-color-collapsed) !default;
$icon-bg-color: var(--icon-bg-color);
$icon-size: var(--icon-size) !default;
$submenu-indent: var(--submenu-indent) !default;
$breakpoint-xs: var(--breakpoint-xs) !default;
$breakpoint-sm: var(--breakpoint-sm) !default;
$breakpoint-md: var(--breakpoint-md) !default;
$breakpoint-lg: var(--breakpoint-lg) !default;
$breakpoint-xl: var(--breakpoint-xl) !default;
$breakpoint-xxl: var(--breakpoint-xxl) !default;

@import '~react-pro-sidebar/dist/scss/styles.scss';
