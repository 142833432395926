$green-bg: #608f43;
$green-color: rgb(89, 160, 77);
$green-border-color: #5cb85c;
$dark-color: #474c55;

:root {
    --green-bg: #608f43;
    --green-color: #708c1c;
    --green-border-color: #5cb85c;
}

// Form
.form-control:focus {
    border-color: $green-border-color !important;
    box-shadow: 0 0 0 0.25rem rgba(5, 99, 5, 0.25) !important;
}

.has-error .form-control:focus {
    border-color: $green-border-color !important;
}

.form-check-input:checked {
    background-color: #ffffff !important;
    border-color: $green-border-color !important;
}

.form-check-input:checked[type='radio'] {
    background-image: url('../../../public/image/icon/green-circle.svg') !important;
}

.form-check-input:checked[type='checkbox'] {
    background-image: url('../../../public/image/icon/green-check.svg') !important;
}

.form-select:focus {
    border-color: $green-border-color !important;
    box-shadow: 0 0 0 0.25rem rgba(5, 99, 5, 0.25) !important;
}

// Button

.btn-primary {
    background-color: $green-bg;
    border-color: $green-border-color;
    font-weight: 600;
}

.btn-primary:disabled {
    background-color: $green-bg;
    border-color: $green-border-color;
    font-weight: 600;
    opacity: 0.5;
}

.btn-primary:hover {
    background-color: $green-bg;
    border-color: $green-border-color;
}

.btn-primary:focus,
.btn-primary:active {
    background-color: $green-bg;
    box-shadow: 0 0 0 0.25rem rgba(5, 99, 5, 0.25);
    border-color: $green-border-color;
}

.btn-outline-primary {
    border-color: $green-border-color;
    color: $green-color;
    font-weight: 600;
}

.btn-outline-primary:disabled {
    border-color: $green-border-color;
    font-weight: 600;
    opacity: 0.5;
}

.btn-outline-primary:hover {
    background-color: $green-bg;
    border-color: $green-border-color;
}

.btn-outline-primary:focus,
.btn-primary:active {
    box-shadow: 0 0 0 0.25rem rgba(5, 99, 5, 0.25);
    border-color: $green-border-color;
    background-color: $green-bg;
}

.btn-default:hover, .btn-default:focus, .btn-default:active {
    border-color: #d4d7db;
}

.btn-icon {
    background-color: #f7f8f9;
}

// Text
.text-primary {
    color: $green-color !important;
}

.text-gray {
    color: #474c55 !important;
}

.text-green-light {
    color: rgba(99, 140, 28, 0.3);
}

.text-success {
    color: #00b630 !important;
}

// Border
.border-primary {
    border-color: $green-border-color !important;
    border-width: 2px !important;
}

.border-success {
    border-color: #638c1c;
}

.border-warning {
    border-color: #DBAA00;
}

.btn-edit {
    border: 1px solid #d4d7db;
    border-radius: 2px;
    padding: 10px 14px;
    color: $dark-color;
    border-radius: 2px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
}

//nav-bar
.nav-tabs .nav-link.active {
    color: $green-color;
    border-bottom: 2px solid $green-border-color;
    border-top: none;
    border-left: none;
    border-right: none;
}

.nav-link {
    color: #a4a7ab;
}

.nav-link:hover {
    color: $green-color;
}

//Background
.bg-secondary {
    background-color: #ffffff !important;
}

.bg-cargill-gray {
    background-color: #f7f8fa !important;
}

.bg-cargill-gray-darker {
    background-color: #eef1f5 !important;
}

.bg-primary {
    background-color: $green-bg !important;
}

.bg-blue {
    background-color: #0a68f4 !important;
}

.bg-secondary-danger {
    background-color: #fff5f5 !important;
}

.bg-secondary-primary {
    background-color: #638c1c1a;
}

// utilities
.maw-0 {
    max-width: none !important;
}

.max-width-800 {
    max-width: 800px !important;
}
