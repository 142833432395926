@media screen and (max-width: 576px) {
    .horizontal-scroll {
        overflow-x: scroll;
    }

    .horizontal-scroll > .row {
        flex-wrap: nowrap;
        min-width: 680px;
    }

    .horizontal-scroll > .row > .col-1 {
        max-width: 60px;
    }

    .horizontal-scroll > .row > .col-2 {
        max-width: 150px;
    }

    .horizontal-scroll > .row > .col-8 {
        max-width: 400px;
    }

    .horizontal-scroll {
        overflow-x: scroll;
    }

    .child-horizontal-scroll > .row {
        flex-wrap: nowrap;
        min-width: 680px;
    }

    .child-horizontal-scroll > .row > .col-1 {
        max-width: 60px;
    }

    .child-horizontal-scroll > .row > .col-2 {
        max-width: 150px;
    }

    .child-horizontal-scroll > .row > .col-8 {
        max-width: 400px;
    }
}

.report-detail-fixed-header {
    position: sticky;
    top: 0;
    z-index: 1;
}
