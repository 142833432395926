.hello {
    color: #474c55;
    font-size: 28px;
}

.login {
    color: #62666c;
    font-size: 16px;
}

.otp-box {
    width: 54px !important;
    height: 54px !important;
    border: 1px solid #e5e9ee;
    border-radius: 2px;
    margin-right: 8px;
    text-align: center;
    @media screen and (max-width: 576px) {
        width: 40px;
        height: 40px;
    }
}

.mobile-container {
    @media screen and (max-width: 576px) {
        border-radius: 50px;
        transform: translateY(-50px);
        background: white;
    }
}

.farm-logo-mobile {
    @media screen and (max-width: 576px) {
        padding-top: 3rem !important;
    }
}

.otp-box:focus {
    outline: none !important;
    border: 1px solid var(--green-border-color) !important;
}

.change-language {
    position: absolute;
    z-index: 100;
    width: auto !important;
    right: 6%;
    top: 6.77%;
    bottom: 89.78%;
    width: auto;
    border-radius: 20px;
    background-color: #ffffff;

    .language-icon {
        width: 14px;
        height: 14px;
    }
}
