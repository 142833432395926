.tilt-in-right-2 {
    -webkit-animation: tilt-in-right-2 0.6s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    animation: tilt-in-right-2 0.6s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2022-7-23 15:36:23
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation tilt-in-right-2
 * ----------------------------------------
 */
@-webkit-keyframes tilt-in-right-2 {
    0% {
        -webkit-transform: rotateX(30deg) translateX(300px) skewX(-30deg);
        transform: rotateX(30deg) translateX(300px) skewX(-30deg);
        opacity: 0;
    }
    100% {
        -webkit-transform: rotateX(0deg) translateX(0) skewX(0deg);
        transform: rotateX(0deg) translateX(0) skewX(0deg);
        opacity: 1;
    }
}
@keyframes tilt-in-right-2 {
    0% {
        -webkit-transform: rotateX(30deg) translateX(300px) skewX(-30deg);
        transform: rotateX(30deg) translateX(300px) skewX(-30deg);
        opacity: 0;
    }
    100% {
        -webkit-transform: rotateX(0deg) translateX(0) skewX(0deg);
        transform: rotateX(0deg) translateX(0) skewX(0deg);
        opacity: 1;
    }
}

.swing-in-right-fwd {
    -webkit-animation: swing-in-right-fwd 1s cubic-bezier(0.175, 0.885, 0.32, 1.275) both;
    animation: swing-in-right-fwd 1s cubic-bezier(0.175, 0.885, 0.32, 1.275) both;
}

/* ----------------------------------------------
 * Generated by Animista on 2022-7-29 22:14:56
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation swing-in-right-fwd
 * ----------------------------------------
 */
@-webkit-keyframes swing-in-right-fwd {
    0% {
        -webkit-transform: rotateY(-100deg);
        transform: rotateY(-100deg);
        -webkit-transform-origin: right;
        transform-origin: right;
        opacity: 0;
    }
    100% {
        -webkit-transform: rotateY(0);
        transform: rotateY(0);
        -webkit-transform-origin: right;
        transform-origin: right;
        opacity: 1;
    }
}
@keyframes swing-in-right-fwd {
    0% {
        -webkit-transform: rotateY(-100deg);
        transform: rotateY(-100deg);
        -webkit-transform-origin: right;
        transform-origin: right;
        opacity: 0;
    }
    100% {
        -webkit-transform: rotateY(0);
        transform: rotateY(0);
        -webkit-transform-origin: right;
        transform-origin: right;
        opacity: 1;
    }
}
