.sale-card:hover {
    background-color: #638c1c;
}

.sale-card:active {
    background-color: #638c1c;
}

.sale-card {
    background-color: #eaf7d4;
}

.border-dash {
    border: 1px dashed #d0d4da;
}

.scrollable {
    white-space: nowrap;
    overflow-x: auto;
}
