.title {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 26px;
    color: #474c55;
}

.main-news-title {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    color: #474c55;
}

.main-news-box {
    min-height: 200px;
}

.main-news-detail {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #62666c;
}

.xs-news-title {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    line-clamp: 2;
    -webkit-box-orient: vertical;
}

.xs-news-detail {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* number of lines to show */
    line-clamp: 3;
    -webkit-box-orient: vertical;
}

.create-at {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #a4a7ab;
}
.create-at-sub {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #a4a7ab;
}

.sub-news-title {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #474c55;
}

.sub-news-detail {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #62666c;
}

.avatar-user {
    width: 5.5rem;
    height: 5.5rem;
    object-fit: cover;
}

.avatar-farm {
    width: 3.5rem;
    object-fit: cover;
    height: 3.5rem;
}

.farm-title {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #474c55;
}

.farm-address {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #62666c;
}

.user-name {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 26px;
    color: #474c55;
}

.dashboard-evaluate-box {
    background-color: #f9f9f9;
    min-width: 115px;
}

.dashboard-xs-user-info {
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.08);
}

.dashboard-xs-header {
    background-color: rgba(0, 131, 60, 0.1);
    border-radius: 0px 0px 24px 24px;
}

.xs-picture {
    height: 150px;
    width: 100%;
    object-fit: cover;
    border-radius: 2px 2px 0px 0px;
}

.btn-1 {
    background: linear-gradient(110.75deg, #e18165 0%, #e75b3b 103.82%) !important;
    border-radius: 0px 12px !important;
}

.btn-2 {
    background: linear-gradient(110.75deg, #f8b471 0%, #ec9d2b 103.82%) !important;
    border-radius: 12px 0px !important;
}

.btn-3 {
    background: linear-gradient(110.75deg, #89b36e 0%, #67954a 103.82%) !important;
    border-radius: 0px 12px !important;
}

.btn-4 {
    background: linear-gradient(110.75deg, #64a0fb 0%, #2879f3 103.82%) !important;
    border-radius: 12px 0px !important;
}

.introduction-div {
    background-image: url('../../../../public/image/picture/pig-introduction.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: right;
}

.introduction-border {
    width: 30%;
    border-top-right-radius: 50%;
    border-bottom-right-radius: 50%;

    @media screen and (max-width: 576px) {
        width: 50%;
    }
}
