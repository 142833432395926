.farm-name {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    text-transform: capitalize;
    color: #474c55;
}

.farm-criteria {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #65686f;
}

.farm-point {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 23px;
    color: #121216;
}

.farm-risk {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 23px;
}

.created-by {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #a4a7ab;
}

.created-by-name {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #62666c;
}

.created-date {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #a4a7ab;
}

.report-select-farm-dropdown {
    background-color: #fff !important;
    border: 1px solid #e5e9ee !important;
}

.dropdown-toggle::after {
    display: none !important;
}
