.clickable {
    cursor: pointer;
}

.theme-picture {
    object-fit: cover;
    height: 100%;
    width: 100%;
}

.avatar {
    vertical-align: middle;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    object-fit: cover;
}

.app-bg {
    background-color: #f7f8fa;
}

.div-shadow {
    box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
}

.div-shadow-1 {
    box-shadow: rgb(204, 219, 232) 3px 3px 6px 0px inset, rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset;
}

.div-shadow-2 {
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px, rgba(0, 0, 0, 0.3) 0px 7px 13px -3px, rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
}

.div-picking:hover {
    border: 1px solid var(--green-border-color);
}

.div-picking-bg:hover {
    background-color: #f7f8fa;
}

.question-picking-bg-correct:hover {
    background-color: rgba(32, 150, 99, 0.1);
}

.question-picking-bg-wrong:hover {
    background-color: rgba(231, 50, 50, 0.1);
}

.drop-zone {
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}
